import React from 'react'
import ComparisonTemplate from '../../components/Comparison/ComparisonTemplate'

import adoricLogo from '../../images/comparison-logo-images/adoric.png'

const Adoric = () => {

    const websiteIntegrationArray = [
        {
            title: 'WordPress Plugin',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Shopify App',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Google Tag Manager',
            optinly: true,
            integrationApp: true
        },
    ]

    const integrationArray = [
        {
            title: 'MailChimp',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'MailerLite',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'SendGrid',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'ConvertKit',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Zapier',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Sendinblue',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Campaign Monitor',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Intercom',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Integrately',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Pabbly',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Moosend',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Automizy',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Drip',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Integromat',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Webhooks',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'SendFox',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Klaviyo',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'ActiveCampaign',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'GetResponse',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Customer.io',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Hubspot',
            optinly: true,
            integrationApp: true
        },
    ]

    const pricingArray = [
        {
            title: 'Free Plan',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Highest Paid Plan',
            optinly: '$25/Month',
            integrationApp: '$199/Month'
        },
        {
            title: 'Unlimited Page Views',
            optinly: true,
            integrationApp: false
        },
    ]

    const campaignsArray = [
        {
            title: 'Lightbox Popup ',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Fullscreen Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Floating Bars',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Spin the Wheel Campaign',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Slide-in',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Sidebar Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Inline/After Post Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Phone Field',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Countdown Timer',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Success View',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Smart Success Display for Subscribed Visitors',
            optinly: true,
            integrationApp: true
        },
    ]

    return (
        <>
            <ComparisonTemplate 
                metaTitle="Optinly vs Adoric Pop-up Builder"
                metaDescription="Compare Optinly with Adoric pop-up builder and discover the unique features that set it apart. Find out why Optinly is the best choice for your pop-up needs."

                otherProductLogo={adoricLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Adoric"
                homeTitle="Optinly - The Best Alternative to Adoric"
                homeDescription="Perfect alternative to Adoric. More Conversions and revenue without having to paymore. Explore what makes Optinly the best alternative to Adoric."

                websiteIntegrationArray={websiteIntegrationArray}
                integrationArray={integrationArray}
                pricingArray={pricingArray}
                campaignsArray={campaignsArray}

                FAQQuestion1="Do both Adoric and Optinly have free plans?"
                FAQAnswer1="No. Adoric does not have a free plan. Whereas Optinly comes with a forever-free plan."

                FAQQuestion2="Can I create a spin-the-wheel coupon popup with both Optinly and Adoric?"
                FAQAnswer2="Yes. You can create spin wheel coupon popups with both the popup plugins. The only difference is pricing - Optinly offers you spin to win popups at $25 while Adoric costs you $80 per month."

                FAQQuestion3="Which tool has a clean User Interface?" 
                FAQAnswer3="Both Optinly and Adoric are extremely good when it comes to user interface and design. The best thing about Optinly is its goal-based approach which lets you create campaigns in less than 4 simple steps."

                FAQQuestion4="Which tool allows me to create exit-intent popups?"
                FAQAnswer4="You can add exit triggers to your popup campaigns using both Optinly and Adoric. The only difference being, Optinly’s free version has exit triggers while Adoric costs users $47 per month."

                FAQQuestion5="Which popup plugin offers unlimited page views?"
                FAQAnswer5="Optinly does. Both Optinly’s free version and paid version don’t have a cap on the number of sessions and pageviews. Whilst when you use Adoric the limit on the number of page views increases as the pricing increases."
            />
        </>
    )
}

export default Adoric
